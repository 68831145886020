<template>
    <form action="" class="questionTabsContainer__commentSection__form">
        <input v-model="comment" type="text" class="questionTabsContainer__commentSection__comment"
        placeholder="Add a Comment ..." />

        <div class="btn btn__primary questionTabsContainer__commentSection__button">
        <a @click="addComment" href="javascript:void(0)">Add Comment</a>
        </div>
    </form>
</template>

<script>
    export default {
        props:['answer_id'],
        data() {
            return {
                comment:'',
                processing:false
            }
        },
        methods: {

            addComment: function() {
                var vm = this;
                var data = new FormData();

                data.append("question_id", this.answer_id);
                data.append("parent_id", 0);
                data.append("body", this.comment);

                if (vm.processing === true) {
                    return false;
                }

                vm.processing = true;

                axios
                    .post('question/comment/add', data)
                    .then(function(response) {
                        if(response.data.success){
                            vm.comment = '';
                            Vue.$toast.open({
                            message: response.data.message,
                            type: "success",
                            });
                        }else{
                            Vue.$toast.open({
                                message: response.data.message,
                                type: "error",
                            });
                        }
                        vm.processing = false;
                    })
                    .catch(function(errors) {
                        console.log("Event create exception", errors);
                         Vue.$toast.open({
                            message: errors.message,
                            type: "error",
                        });
                        vm.processing = false;
                    });
                },
        }
    }
</script>
