/**
 * First we will load all of this project's JavaScript dependencies which
 * includes Vue and other libraries. It is a great starting point when
 * building robust, powerful web applications using Vue and Laravel.
 */

require('../bootstrap');
// require('../plugins');

window.Vue = require('vue');

/**
 * The following block of code may be used to automatically register your
 * Vue components. It will recursively scan this directory for the Vue
 * components and automatically register them with their "basename".
 *
 * Eg. ./components/ExampleComponent.vue -> <example-component></example-component>
 */

// const files = require.context('./', true, /\.vue$/i)
// files.keys().map(key => Vue.component(key.split('/').pop().split('.')[0], files(key).default))

Vue.component('example-component', require('./components/ExampleComponent.vue').default);

/**
 * Next, we will create a fresh Vue application instance and attach it to
 * the page. Then, you may begin adding components to this application
 * or customize the JavaScript scaffolding to fit your unique needs.
 */


import vueSmoothScroll from 'vue2-smooth-scroll'
import VueToast from 'vue-toast-notification'; // https://www.npmjs.com/package/vue-toast-notification
import 'vue-toast-notification/dist/theme-default.css';


Vue.use(vueSmoothScroll)
Vue.use(VueToast);


// Global Components
import addComment from './components/addComment';
import replyComment from './components/replyComment';
import likeComment from './components/likeComment';
import select2Product from './components/select2Product';


import helpers from './mixins/helpers';


const app = new Vue({
    el: '#vue-app',
    mixins: [helpers],
    components: {
        add_comment: addComment,
        reply_comment: replyComment,
        like_comment: likeComment,
        select2_product:select2Product
        
    }
});