var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "a",
    {
      staticClass: "like-item",
      class: { active: _vm.like },
      attrs: { href: "javascript:void(0);" },
      on: { click: _vm.likeQuestion }
    },
    [_c("i", { staticClass: "fa fa-heart", attrs: { "aria-hidden": "true" } })]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }