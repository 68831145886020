export default {
    data: function () {
        return {
            comment:'',
            replyComment:false,
        };
    },
    mounted: function () {

        // var vm = this;
        // var dates = $('#event-search-dateranges').parent().parent().data('dateranges');
        // dates = (typeof dates != 'undefined' &&  dates != '')?dates.split('~'):'';
        // $('#event-search-dateranges').val(dates);
        // vm.dateranges = dates

    },
    updated: function () {

    },
    created: function () {
        console.log('Printing from the Mixin');
    },
    methods: {
        questionFocusInput() {
            var vm = this;
            setTimeout(() => {
                vm.$refs.ask_question.focus();    
            }, 500);
        },
        addComment() {
            var vm = this;
            var comment = '';
        },
        
        invoiceStatus(status){
            var class_n='info';
        switch(status){
            case 'paid':
                class_n="success";
            break;
            case 'late':
                class_n="danger";
            break;
            default:
                class_n="info";
            break;
        }

        return "<span class='badge badge-"+class_n+"'><strong>"+status+"</strong></span>";
        }

    },
    filters: {
        removeDashes: function(str){
            var newStr = str.replace(/-/g, " ");
            return newStr   
        },
        assetPath: function (img) {
            var path = window.axios.defaults.baseURL + '/images/' + img;
            return path;
        },
        checkObj: function (obj) {
            return (obj == '') ? false : true
        },
        url: function (path) {
            var path = window.axios.defaults.baseURL + '/' + path;
            return path;
        },
        defaultValue(value){
            return ((value==null || value=='' || value=="")? 'N/A': value);

        },
        getDateByDT(dt){
            if(dt==null || dt=='' || dt==""){
                return 'N/A';
            }
            else{
            var d = new Date(dt);
            var dd = String(d.getDate()).padStart(2, '0');
            var mm = String(d.getMonth() + 1).padStart(2, '0'); //January is 0!
            var yyyy = d.getFullYear();
            return dd + '/' + mm + '/' + yyyy;
            }
        },
    }
}    
