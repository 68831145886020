const API_URL = document.head.querySelector('meta[name="base_url"]').content;
// import { bus } from '../app'

export default {
    data: function() {
        return {
            select2:{
                product: {
                    tags: true,
                    allowClear: true,
                    multiple:false,
                    tokenSeparators: [",", "#",''],
                    placeholder: "Select Product",
                    /* the next 2 lines make sure the user can click away after typing and not lose the new tag */
                    selectOnClose: false,
                    closeOnSelect: true,
                    minimumInputLength: 1,
                    createTag: function (params) {
                       return null;
                    },
                    ajax: {
                        url: API_URL+'/data/shopify/products',
                        dataType: 'json',
                        type: "GET",
                        quietMillis: 50,
                        data: function (params) {
                            var query = {
                                q: params.term,
                                type:'',
                                offset:0,
                                limit:20
                            }
                            // Query parameters will be ?search=[term]&type=public
                            return query;
                        },
                        processResults: function (data) {

                            // return data.data;
                            return {
                                results: $.map(data, function (item) {
                                    return {
                                        text: item.text,
                                        id: item.id
                                    }
                                })
                            };
                        }
                    }
                },
                claim_business: {
                    tags: true,
                    allowClear: true,
                    multiple:false,
                    tokenSeparators: [",", "#",''],
                    placeholder: "Select One",
                    /* the next 2 lines make sure the user can click away after typing and not lose the new tag */
                    selectOnClose: false,
                    selectOnBlur: true,
                    closeOnSelect: true,
                    minimumInputLength: 1,
                    createTag: function (params) {
                       return null;
                    },
                    ajax: {
                        url: API_URL+'venue/unclaimed-business',
                        dataType: 'json',
                        type: "GET",
                        quietMillis: 50,
                        data: function (params) {
                            var query = {
                                q: params.term,
                                offset:0,
                                limit:50
                            }
                            // Query parameters will be ?search=[term]&type=public
                            return query;
                        },
                        processResults: function (data) {

                            if(data.payload.content.length == 0) {
                                bus.$emit('claimBusinessNotFound');
                            }else 

                            return {
                                results: $.map(data.payload.content, function (item) {
                                    return {
                                        text: item.title_type,
                                        slug: item.title,
                                        id: item.id
                                    }
                                })
                            };
                        }
                    }
                },
                editEventBusiness: {
                    tags: true,
                    allowClear: true,
                    multiple:false,
                    disabled:true,
                    tokenSeparators: [",", "#",''],
                    placeholder: "Select One",
                    /* the next 2 lines make sure the user can click away after typing and not lose the new tag */
                    selectOnClose: false,
                    closeOnSelect: true,
                    minimumInputLength: 1,
                    createTag: function (params) {
                       return null;
                    },
                    ajax: {
                        url: API_URL+'venue/business-list/',
                        dataType: 'json',
                        type: "GET",
                        quietMillis: 50,
                        data: function (params) {
                            var query = {
                                q: params.term,
                                type:'',
                                offset:0,
                                limit:100
                            }
                            // Query parameters will be ?search=[term]&type=public
                            return query;
                        },
                        processResults: function (data) {

                            return {
                                results: $.map(data.payload.business, function (item) {
                                    return {
                                        text: item.title_type,
                                        slug: item.title,
                                        id: item.id
                                    }
                                })
                            };
                        }
                    }
                },
                city: {
                    tags: false,
//                    allowClear: true,
                    multiple:false,
                    placeholder: "City",
                    /* the next 2 lines make sure the user can click away after typing and not lose the new tag */
                    selectOnClose: false,
                    closeOnSelect: true,
                    minimumInputLength: 1,
                    maximumSelectionLength: 5,
                    createTag: function (params) {
                        return null;
                    },
                    ajax: {
                        url: API_URL+'main/cities/',
                        dataType: 'json',
                        type: "GET",
                        quietMillis: 50,
                        data: function (params) {
                            var query = {
                                q: params.term,
                                offset:0,
                                limit:100
                            }
                            // Query parameters will be ?search=[term]&type=public
                            return query;
                        },
                        processResults: function (data) {

                            console.log(data);
                            return {
                                results: $.map(data.payload.content, function (item) {
                                    return {
                                        text: item.name,
                                        slug: item.name,
                                        id: item.id
                                    }
                                })
                            };
                        }
                    },
                 
                },
                event_category: {
                    tags: false,
                    multiple:false,
                    allowClear: true,
                    placeholder: "Select One",
                    selectOnClose: false,
                    closeOnSelect: true,
                },
                event_type: {
                    tags: false,
                    multiple:false,
                    allowClear: true,
                    placeholder: "Select One",
                    selectOnClose: false,
                    closeOnSelect: true,
                    
                },
                single_select2_default_config: {
                    tags: false,
                    multiple:false,
                    allowClear: true,
                    placeholder: "Select One",
                    selectOnClose: false,
                    closeOnSelect: true,
                    
                },
                edit_event_type: {
                    tags: false,
                    multiple:false,
                    allowClear: true,
                    placeholder: "Select One",
                    selectOnClose: false,
                    closeOnSelect: true,
                    disabled:true
                },
                ticket_type: {
                    tags: false,
                    multiple:false,
                    allowClear: true,
                    placeholder: "Select One",
                    selectOnClose: false,
                    closeOnSelect: true,
                },
                event_theme: {
                    tags: false,
                    multiple:false,
                    allowClear: true,
                    placeholder: "Select One",
                    selectOnClose: false,
                    closeOnSelect: true,
                },
                event_genre: {
                    tags: false,
                    multiple:false,
                    allowClear: true,
                    placeholder: "Select One",
                    selectOnClose: false,
                    closeOnSelect: true,
                },
                category: {
                    tags: false,
                    multiple:false,
                    allowClear: true,
                    placeholder: "Select One",
                    selectOnClose: false,
                    closeOnSelect: true,
                },
                sub_category: {
                    tags: false,
                    multiple:false,
                    allowClear: true,
                    placeholder: "Select One",
                    selectOnClose: false,
                    closeOnSelect: true,
                },
                parking_type: {
                    tags: false,
                    multiple:false,
                    allowClear: true,
                    placeholder: "Select One",
                    selectOnClose: false,
                    closeOnSelect: true,
                },
                business_type_select: {
                    tags: false,
                    multiple:false,
                    allowClear: true,
                    placeholder: "Select One",
                    selectOnClose: false,
                    closeOnSelect: true,
                },

            }
        };
    },
    created: function () {
        console.log('Printing from the Mixin');
    },
    methods: {
        displayMessage: function () {
            console.log('Now printing from a mixin function');
        },
        limitStr: function (str,limit) {
            
            var limitStr = str;
            if(str.length > limit ){
                limitStr = str.substring(0,limit)+".."
            }
            return limitStr;
        },
        isActiveFilter:function(value){
            return value != ''? true :false;
        },
        isActiveDateFilter:function(date){
            return date.start != '' & date.end != ''? true :false;
        },
        url: function (path) {
            var path = window.axios.defaults.baseURL+'/'+path;
            return path;
        }
        
    },
    filters: {

        assetPath: function (img) {
            var path = window.axios.defaults.baseURL+'/images/'+img;
            return path;
        },
        fullAddress: function (event) {
            var address = event.city
            address += (event.state != null)?  ', '+ (event.state) : ''
            address += (event.zip_code != null) ? ', '+ (event.zip_code) : ''
            return address;
        },
        checkObj: function (obj) {
            return (obj == '')?false:true
        },
        url: function (path) {
            var path = window.axios.defaults.baseURL+'/'+path;
            return path;
        }
    }
}