var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c("div", { staticClass: "questionTabsContainer__iconContainer extra" }, [
      _c(
        "a",
        {
          staticClass: "questionTabsContainer__iconContainer__replyIcon",
          attrs: { href: "javascript:void(0)" },
          on: { click: _vm.isreply }
        },
        [_vm._v("Reply")]
      ),
      _vm._v(" "),
      _c("a", {
        staticClass: "questionTabsContainer__iconContainer__messageIcon",
        attrs: { href: "#" }
      })
    ]),
    _vm._v(" "),
    _c("div", { staticClass: "row" }, [
      _vm.isActive
        ? _c(
            "form",
            {
              staticClass: "questionTabsContainer__commentSection__form",
              attrs: { action: "" }
            },
            [
              _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.comment,
                    expression: "comment"
                  }
                ],
                staticClass: "questionTabsContainer__commentSection__comment",
                attrs: { type: "text", placeholder: "Add a Comment ..." },
                domProps: { value: _vm.comment },
                on: {
                  input: function($event) {
                    if ($event.target.composing) {
                      return
                    }
                    _vm.comment = $event.target.value
                  }
                }
              }),
              _vm._v(" "),
              _c(
                "div",
                {
                  staticClass:
                    "btn btn__primary questionTabsContainer__commentSection__button"
                },
                [
                  _c(
                    "a",
                    {
                      attrs: { href: "javascript:void(0)" },
                      on: { click: _vm.addComment }
                    },
                    [_vm._v("Reply")]
                  )
                ]
              )
            ]
          )
        : _vm._e()
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }