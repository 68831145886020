<template>
    <a href="javascript:void(0);" @click="likeQuestion" class="like-item" :class="{'active':like}">
        <i class="fa fa-heart" aria-hidden="true"></i>
        <!-- <span v-if="counts > 0">{{ counts }}</span> -->
    </a>
</template>

<script>
    export default {
        props:[
            'id',
            'like_counts',
            'is_liked',
            'model'
        ],
        data() {
            return {
                like:false,
                counts:0,
                processing:false
            }
        },
        created() {
            this.like = this.is_liked? 1:0;
            this.counts = this.like_counts;
        },
        methods: {

            likeQuestion: function() {
                var vm = this;
                var data = new FormData();

                var likeParam = vm.like?0:1;

                vm.counts = likeParam ? vm.counts + 1: vm.counts - 1; 

                data.append("id", vm.id);
                data.append("is_like", likeParam);

                if (vm.processing === true) {
                    return false;
                }

                vm.processing = true;

                axios.post('question/toggle-like', data)
                    .then(function(response) {
                        
                        if(response.data.success){
                            vm.comment = '';
                            // Vue.$toast.open({
                            // message: response.data.message,
                            // type: "success",
                            // });
                            vm.like = likeParam
                        }else{
                            Vue.$toast.open({
                                message: response.data.message,
                                type: "error",
                            });
                        }
                        vm.processing = false;
                    })
                    .catch(function(errors) {
                        console.log("Event create exception", errors);
                         Vue.$toast.open({
                            message: errors.message,
                            type: "error",
                        });
                        vm.processing = false;
                    });
                },
        }
    }
</script>
