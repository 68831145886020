<template>
    <Select2  v-model="myValue"  name="product_id"  class="form-control search-control form-control-lg " 
    :options="myOptions" 
    :settings="select2.product" />
</template>

<script>

    import Select2 from 'v-select2-component';
    import selectConfig from "./../mixins/select2configs";

    export default {
        props:['products','value'],
        mixins:[selectConfig],
        created() {
            // this.myOptions = this.products;
        },
        data() {
            return {
                comment:'',
                myValue:'',
                myOptions: [
                    // {"id":"4682133012573","text":"iPhone 3Gs Glass Repair"},
                    // {"id":"4682136354909","text":"iPhone 3G Glass and LCD Repair"},
                    // {"id":"4682144907357","text":"iPhone 3G Glass-Digitizer and back housing replacement"}

                ],
                processing:false
            }
        },
        components:{
            Select2
        },
        methods: {

            addComment: function() {
                var vm = this;
                var data = new FormData();

                data.append("question_id", this.answer_id);
                data.append("parent_id", 0);
                data.append("body", this.comment);

                if (vm.processing === true) {
                    return false;
                }

                vm.processing = true;

                axios
                    .post('question/comment/add', data)
                    .then(function(response) {
                        if(response.data.success){
                            vm.comment = '';
                            Vue.$toast.open({
                            message: response.data.message,
                            type: "success",
                            });
                        }else{
                            Vue.$toast.open({
                                message: response.data.message,
                                type: "error",
                            });
                        }
                        vm.processing = false;
                    })
                    .catch(function(errors) {
                        console.log("Event create exception", errors);
                         Vue.$toast.open({
                            message: errors.message,
                            type: "error",
                        });
                        vm.processing = false;
                    });
                },
        }
    }
</script>
