<template>
    <div>
        <div class="questionTabsContainer__iconContainer extra">
            <a href="javascript:void(0)" @click="isreply" class="questionTabsContainer__iconContainer__replyIcon">Reply</a>
            <a href="#" class="questionTabsContainer__iconContainer__messageIcon"></a>
        </div>
        <div class="row">
            <form v-if="isActive" action="" class="questionTabsContainer__commentSection__form">
                <input v-model="comment" type="text" class="questionTabsContainer__commentSection__comment"
                placeholder="Add a Comment ..." />

                <div class="btn btn__primary questionTabsContainer__commentSection__button">
                <a @click="addComment" href="javascript:void(0)">Reply</a>
                </div>
            </form>
        </div>
    </div>
</template>

<script>
    export default {
        props:['answer_id','parent_id'],
        data() {
            return {
                comment:'',
                processing:false,
                isActive:false,
            }
        },
        methods: {
            isreply: function() {
               this.isActive = !this.isActive;
            },
            addComment: function() {
                var vm = this;
                var data = new FormData();
                 var parentID = typeof vm.parent_id != 'undefined'?vm.parent_id : 0; 

                data.append("question_id", this.answer_id);
                data.append("parent_id", parentID);
                data.append("body", this.comment);

                if (vm.processing === true) {
                    return false;
                }

                vm.processing = true;

                axios
                    .post('question/comment/add', data)
                    .then(function(response) {
                        if(response.data.success){
                            vm.comment = '';
                            Vue.$toast.open({
                            message: response.data.message,
                            type: "success",
                            });
                            vm.isActive = false;
                        }else{
                            Vue.$toast.open({
                                message: response.data.message,
                                type: "error",
                            });
                        }

                        vm.processing = false;
                    })
                    .catch(function(errors) {
                        console.log("Event create exception", errors);
                         Vue.$toast.open({
                            message: errors.message,
                            type: "error",
                        });
                        vm.processing = false;
                    });
                },
        }
    }
</script>
