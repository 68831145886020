var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("Select2", {
    staticClass: "form-control search-control form-control-lg ",
    attrs: {
      name: "product_id",
      options: _vm.myOptions,
      settings: _vm.select2.product
    },
    model: {
      value: _vm.myValue,
      callback: function($$v) {
        _vm.myValue = $$v
      },
      expression: "myValue"
    }
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }